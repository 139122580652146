import React, { useCallback, useEffect, useState } from "react";
import "./Carousel.css";

const Carousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [style, setStyle] = useState({
    transform: "translateX(-100%)",
    transition: "transform 0.5s ease-in-out",
  });

  const nextSlide = useCallback(() => {
    if (currentIndex === images.length - 1) {
      // 마지막 이미지에서 첫 번째 이미지로 전환
      setStyle({
        transform: `translateX(-${(currentIndex + 2) * 100}%)`,
        transition: "transform 0.5s ease-in-out",
      });
      
      setTimeout(() => {
        setStyle({
          transform: "translateX(-100%)",
          transition: "none",
        });
        setCurrentIndex(0);
      }, 500);
    } else {
      setStyle({
        transform: `translateX(-${(currentIndex + 2) * 100}%)`,
        transition: "transform 0.5s ease-in-out",
      });
      setCurrentIndex(currentIndex + 1);
    }
  }, [currentIndex, images.length]);

  const prevSlide = useCallback(() => {
    if (currentIndex === 0) {
      // 첫 번째 이미지에서 마지막 이미지로 전환
      setStyle({
        transform: "translateX(0%)",
        transition: "transform 0.5s ease-in-out",
      });
      
      setTimeout(() => {
        setStyle({
          transform: `translateX(-${images.length * 100}%)`,
          transition: "none",
        });
        setCurrentIndex(images.length - 1);
      }, 500);
    } else {
      setStyle({
        transform: `translateX(-${currentIndex * 100}%)`,
        transition: "transform 0.5s ease-in-out",
      });
      setCurrentIndex(currentIndex - 1);
    }
  }, [currentIndex, images.length]);

  useEffect(() => {
    const timer = setInterval(nextSlide, 3000);
    return () => clearInterval(timer);
  }, [nextSlide]);

  return (
    <div className="carousel-container">
      <div className="carousel-wrapper" style={style}>
        <div className="carousel-slide">
          <img src={images[images.length - 1].url} alt="Last" />
        </div>
        {images.map((image, index) => (
          <div key={index} className="carousel-slide">
            <img src={image.url} alt={image.alt} />
          </div>
        ))}
        <div className="carousel-slide">
          <img src={images[0].url} alt="First" />
        </div>
      </div>
      <button className="carousel-button prev" onClick={prevSlide}>
        &lt;
      </button>
      <button className="carousel-button next" onClick={nextSlide}>
        &gt;
      </button>
      <div className="carousel-dots">
        {images.map((_, index) => (
          <span
            key={index}
            className={`carousel-dot ${index === currentIndex ? "active" : ""}`}
            onClick={() => setCurrentIndex(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
