import React from 'react';
import { Link } from 'react-router-dom';
import { arcSolveImage, koacaImage, optimAIImage } from '../../assets/images';
import projectsData from '../../data/projects.json';
import './OutPut.css';

const OutPut = () => {
  const projectImages = {
    arcsolve: arcSolveImage,
    koaca: koacaImage,
    optimai: optimAIImage
  };

  const completedProjects = Object.values(projectsData)
    .filter(project => project.status === 'completed')
    .map(project => ({
      ...project,
      image: projectImages[project.id]
    }));

  return (
    <div className="output fade-in">
      <h1 className="section-title">완료한 프로젝트</h1>
      
      <section className="completed-projects">
        <div className="project-grid">
          {completedProjects.map((project) => (
            <Link to={project.path} key={project.id} className="project-link">
              <div className="card project-card">
                <div className="project-image">
                  <img src={project.image} alt={`${project.name} 프로젝트 이미지`} />
                </div>
                <div className="project-content">
                  <h3>{project.name}</h3>
                  <p className="project-description">{project.description}</p>
                  <div className="project-features">
                    <h4>주요 기능</h4>
                    <ul>
                      {project.features.map((feature, featureIndex) => (
                        <li key={featureIndex}>{feature.title}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="tech-stack">
                    {project.techStack.map((tech, techIndex) => (
                      <span key={techIndex} className="tech-tag">{tech}</span>
                    ))}
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </section>
    </div>
  );
};

export default OutPut;
