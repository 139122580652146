import React from "react";
import {
  meImage,
  owlImage,
} from "../../assets/images";
import Carousel from "../../components/Carousel/Carousel";
import projectsData from "../../data/projects.json";
import "./Home.css";

const Home = () => {
  const projects = [
    { url: meImage, alt: "KorLegends 관련 프로젝트의 대표 이미지" },
    { url: owlImage, alt: "혁신적인 스마트시티 솔루션 이미지" },
  ];

  const projectsList = Object.values(projectsData);

  return (
    <div className="home fade-in">
      <section className="hero">
        <h1>기술로 더 나은 일상을 만듭니다</h1>
        <p>
          기술의 도입을 통해 일상생활의 불편함을 해결하고 새로운 가치를 창출하는
          KorLegends
        </p>
      </section>

      <section className="project-carousel">
        <h2 className="section-title">멤버</h2>
        <Carousel images={projects} />
      </section>

      <section className="philosophy">
        <h2 className="section-title">개발 철학</h2>
        <p className="philosophy-text">
          우리는 기술을 통해 일상생활의 불편함을 해결하고 새로운 가치를 창출하는
          팀입니다. 쏟아지는 기술들을 조합하여 새로운 가치를 창출하는 것에
          최선을 다하고 있습니다. 또한 지속 가능한 개발과 사회적 책임을 중요하게
          생각하며, 모든 프로젝트에서 이러한 가치를 실현하고자 합니다.
        </p>
      </section>

      <section className="projects-and-team">
        <h2 className="section-title">프로젝트 & 팀</h2>
        <div className="project-list">
          <h3>주요 프로젝트</h3>
          <ul>
            {projectsList.map((project) => (
              <li key={project.id} className="project-item">
                <h4>{project.name}</h4>
                <p>{project.description}</p>
                <div className="project-status">
                  상태: {project.status === 'completed' ? '완료' : '진행중'}
                </div>
                <div className="tech-stack">
                  기술 스택: {project.techStack.join(', ')}
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="team">
          <h3>핵심 팀원</h3>
          <ul>
            <li>조경민 - 팀 리더 & 시니어 풀스택 개발자</li>
            <li>김민정 - 프론트엔드 개발자 & UI/UX 기획자</li>
          </ul>
        </div>
      </section>

      <section className="contact">
        <h2 className="section-title">연락처</h2>
        <div className="contact-info">
          <p>이메일: korlegends.ku@gmail.com</p>
          <p>전화: 010-4996-1229</p>
          <p>주소: 서울특별시 성북구 고려대학교 안암캠퍼스 KU</p>
          <p>GitHub: github.com/korlegends</p>
        </div>
        <button
          className="contact-button"
          onClick={() => (window.location.href = "/#/contact")}
        >
          문의하기
        </button>
      </section>
    </div>
  );
};

export default Home;
