import React from 'react';
import { useParams } from 'react-router-dom';
import projectsData from '../../data/projects.json';
import './ProjectDetail.css';

const ProjectDetail = () => {
  const { projectId } = useParams();
  const projectDetails = projectsData[projectId];

  if (!projectDetails) {
    return <div className="error-message">프로젝트를 찾을 수 없습니다.</div>;
  }

  return (
    <div className="project-detail-page fade-in">
      <header className="project-header">
        <h1>{projectDetails.name}</h1>
        <p className="project-subtitle">{projectDetails.description}</p>
      </header>

      <section className="project-overview">
        <h2>프로젝트 개요</h2>
        <p>{projectDetails.overview}</p>
        <div className="tech-stack">
          <h3>기술 스택</h3>
          <div className="tech-tags">
            {projectDetails.techStack.map((tech, index) => (
              <span key={index} className="tech-tag">{tech}</span>
            ))}
          </div>
        </div>
      </section>

      <section className="project-features">
        <h2>주요 기능</h2>
        <div className="features-grid">
          {projectDetails.features.map((feature, index) => (
            <div key={index} className="feature-card">
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="project-challenges">
        <h2>도전 과제</h2>
        <ul className="challenges-list">
          {projectDetails.challenges.map((challenge, index) => (
            <li key={index}>{challenge}</li>
          ))}
        </ul>
      </section>

      <section className="project-outcomes">
        <h2>프로젝트 성과</h2>
        <ul className="outcomes-list">
          {projectDetails.outcomes.map((outcome, index) => (
            <li key={index}>{outcome}</li>
          ))}
        </ul>
      </section>
    </div>
  );
};

export default ProjectDetail; 