import React from "react";
import {
    meImage,
    owlImage,
} from "../../assets/images";
import "./AboutUs.css";

const AboutUs = () => {
  const teamLead = {
    name: "조경민",
    role: "팀 리더 & 시니어 풀스택 개발자",
    image: meImage,
    description:
      "안녕하세요, 저는 조경민입니다. 여러 웹사이트 개발 및 배포 경험을 가진 풀스택 개발자로, 일상생활의 편의를 도모하는 웹 개발에 주력하고 있습니다. 저는 딜레이 없는 차세대 기술을 적용한 서비스 혁신을 꿈꾸며, 팀을 이끌어 혁신적인 프로젝트를 성공적으로 완수해 왔습니다. 최근에는 AI와 빅데이터를 활용한 웹 서비스 개발에 집중하고 있어요.",
  };

  const teamMembers = [
    {
      name: "김민정",
      role: "프론트엔드 개발자 & UI/UX 기획자",
      image: owlImage,
      description:
        "안녕하세요, 김민정입니다. 저는 프론트엔드 개발자이자 UI/UX 기획자로 일하고 있어요. 사용자 중심의 디자인 철학을 바탕으로 직관적이고 아름다운 인터페이스를 구현하는 데 주력하고 있습니다. 최근에는 모션 그래픽을 활용한 인터랙티브 웹 경험 설계에 흥미를 느껴 이 분야를 깊이 탐구하고 있어요.",
    },
  ];

  return (
    <div className="about fade-in">
      <h1 className="section-title">우리 팀 소개</h1>

      <div className="team-lead-card">
        <img
          src={teamLead.image}
          alt={teamLead.name}
          className="team-lead-image"
        />
        <div className="team-lead-info">
          <h2>{teamLead.name}</h2>
          <h3>{teamLead.role}</h3>
          <p>{teamLead.description}</p>
        </div>
      </div>

      <h2 className="section-title">팀 멤버 소개</h2>
      <div className="team-members">
        {teamMembers.map((member, index) => (
          <div key={index} className="team-member-card">
            <img
              src={member.image}
              alt={member.name}
              className="team-member-image"
            />
            <h3>{member.name}</h3>
            <h4>{member.role}</h4>
            <p>{member.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AboutUs;
