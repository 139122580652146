import React from 'react';
import ProjectDetail from '../components/ProjectDetail/ProjectDetail';
import AboutUs from '../pages/AboutUs/AboutUs';
import ContactUs from '../pages/ContactUs/ContactUs';
import Goal from '../pages/Goal/Goal';
import Home from '../pages/Home/Home';
import OutPut from '../pages/OutPut/OutPut';

const MainRoutes = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/about',
    element: <AboutUs />,
  },
  {
    path: '/output',
    element: <OutPut />,
  },
  { 
    path: '/projects/:projectId',
    element: <ProjectDetail />,
  },
  {
    path: '/contact',
    element: <ContactUs />,
  },
  { 
    path: '/goal',
    element: <Goal />,
  }
];

export default MainRoutes;
