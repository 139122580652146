import React from 'react';
import { Link } from 'react-router-dom';
import { arcSolveImage, koacaImage, optimAIImage } from '../../assets/images';
import projectsData from '../../data/projects.json';
import './Goal.css';

const Goal = () => {
  const goals = [
    "많은 대중이 실질적으로 찾고자 하는 정보를 통합 및 가공하여 제공",
    "AI와 로봇 기술의 사회 적용으로 인한 급격한 정보 격차 해소",
    "쏟아지는 정보를 정제하고, 가공하여 수요자에게 적절히 전달하는 서비스 제공"
  ];

  const learningTopics = [
    {
      name: "React",
      purpose: "프론트엔드 개발",
      description: "사용자 중심의 인터랙티브한 웹 애플리케이션 구축을 위해 React의 최신 기능과 훅을 학습하고 있습니다."
    },
    {
      name: "Node.js",
      purpose: "백엔드 개발",
      description: "확장 가능한 서버 아키텍처 구축과 실시간 데이터 처리를 위해 Node.js 생태계를 탐구하고 있습니다."
    },
    {
      name: "Python",
      purpose: "AI/ML 개발",
      description: "AI 모델 개발과 데이터 분석을 위해 Python의 다양한 라이브러리와 프레임워크를 학습하고 있습니다."
    },
    {
      name: "Langchain & Langgraph",
      purpose: "AI 애플리케이션 개발",
      description: "LLM 기반 애플리케이션 개발을 위해 Langchain과 Langgraph를 활용한 AI 파이프라인 구축 방법을 연구하고 있습니다."
    },
    {
      name: "MongoDB",
      purpose: "데이터베이스 관리",
      description: "대규모 데이터 처리와 효율적인 데이터 모델링을 위해 MongoDB의 고급 기능을 학습하고 있습니다."
    }
  ];

  const projectImages = {
    arcsolve: arcSolveImage,
    koaca: koacaImage,
    optimai: optimAIImage
  };

  const ongoingProjects = Object.values(projectsData)
    .filter(project => project.status === 'in_progress')
    .map(project => ({
      ...project,
      image: projectImages[project.id]
    }));

  return (
    <div className="goal fade-in">
      <section className="our-goals">
        <h1 className="section-title">우리의 목표</h1>
        <ul className="goals-list">
          {goals.map((goal, index) => (
            <li key={index} className="goal-item">{goal}</li>
          ))}
        </ul>
      </section>

      <section className="learning-progress">
        <h2>현재 학습 중인 기술</h2>
        <div className="learning-topics">
          {learningTopics.map((topic, index) => (
            <div key={index} className="learning-topic-card">
              <h3>{topic.name}</h3>
              <h4>{topic.purpose}</h4>
              <p>{topic.description}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="ongoing-projects">
        <h2>진행 중인 프로젝트</h2>
        <div className="project-grid">
          {ongoingProjects.map((project, index) => (
            <Link to={project.path} key={index} className="project-link">
              <div className="card project-card">
                <div className="project-image">
                  <img src={project.image} alt={`${project.name} 프로젝트 이미지`} />
                </div>
                <div className="project-content">
                  <h3>{project.name}</h3>
                  <p className="project-description">{project.description}</p>
                  <div className="project-features">
                    <h4>주요 기능</h4>
                    <ul>
                      {project.features.map((feature, featureIndex) => (
                        <li key={featureIndex}>{feature.title}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="tech-stack">
                    {project.techStack.map((tech, techIndex) => (
                      <span key={techIndex} className="tech-tag">{tech}</span>
                    ))}
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Goal;